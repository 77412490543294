<template>
    <div>
        <div class="payment-summary">
            <b-card class="text-center" title="Payment Summary">
                <div class="m-1">
                    <div class="mt-2 mb-2">
                        <p class="text-right">
                            <span class="font-weight-bolder float-left"
                                >Method:</span
                            >{{ method }}
                        </p>
                        <p class="text-right">
                            <span class="font-weight-bolder float-left"
                                >Type:</span
                            >{{ isRecurring }}
                        </p>
                        <p class="text-right">
                            <span class="font-weight-bolder float-left"
                                >Plan:</span
                            >{{ plan_name }}
                        </p>
                        <p class="text-right">
                            <span class="font-weight-bolder float-left"
                                >Duration (days):</span
                            >{{ duration }}
                        </p>
                        <p class="text-right">
                            <span class="font-weight-bolder float-left"
                                >Subtotal:</span
                            >{{ subtotal }} {{ this.currency.toUpperCase() }}
                        </p>
                        <p class="text-right">
                            <span class="font-weight-bolder float-left"
                                >Transaction Fee:</span
                            >{{ fee }} {{ this.currency.toUpperCase() }}
                        </p>
                        <p class="text-right">
                            <span class="font-weight-bolder float-left"
                                >Discount:</span
                            >{{ discount }} {{ this.currency.toUpperCase() }}
                        </p>
                        <p class="text-right">
                            <span class="font-weight-bolder float-left"
                                >Total:</span
                            >{{ total }} {{ this.currency.toUpperCase() }}
                        </p>
                    </div>
                    <div v-if="is_card">
                        <b-alert variant="danger" show>
                            <div class="alert-body">
                                <span>
                                    <strong>Do Not Open Dispute.</strong><br />
                                    Please
                                    <a href="mailto:support@leechall.io"
                                        >contact us</a
                                    >
                                    to solve your issue kindly.</span
                                >
                            </div>
                        </b-alert>
                        <b-alert variant="danger" show>
                            <div class="alert-body">
                                <span>
                                    Opening a <strong>Dispute</strong> will
                                    result in <br /><strong
                                        >Immediate Account Termination</strong
                                    ></span
                                >
                            </div>
                        </b-alert>
                    </div>
                    <div v-if="gateway_voucher">
                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <span
                                    >Voucher Code will be sent instantly to the
                                    <strong>Email you Use to Pay</strong> after
                                    payment is successful.</span
                                >
                            </div>
                        </b-alert>
                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <span
                                    >If you do not see the email in a few
                                    minutes, check your “junk mail” folder or
                                    “spam” folder.</span
                                >
                            </div>
                        </b-alert>
                    </div>
                    <div v-if="is_manual">
                        <b-img
                            v-if="qr_png"
                            :src="'/images/payments/methods/' + qr_png + '.png'"
                            width="200"
                            height="200"
                            class="mb-1"
                        ></b-img>

                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <span
                                    >Please send
                                    <strong
                                        >{{ total }}
                                        {{
                                            this.currency.toUpperCase()
                                        }}</strong
                                    >
                                    to <strong>{{ method }}</strong> account
                                    below.</span
                                >
                            </div>
                        </b-alert>
                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <span
                                    ><strong>{{ method }}</strong>
                                    {{ type_account }}:
                                    <strong>{{ account }}</strong></span
                                >
                            </div>
                        </b-alert>
                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <span
                                    >Contact US to receive
                                    <strong>Voucher Code</strong> after you have
                                    <strong>Paid!</strong></span
                                >
                            </div>
                        </b-alert>
                    </div>
                    <div>
                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <span
                                    >PLEASE READ AND ACCEPT
                                    <b-link :to="{ name: 'status' }">
                                        <strong
                                            >HOST STATUS/LIMIT</strong
                                        > </b-link
                                    ><br />
                                    BEFORE MAKING PAYMENT.</span
                                >
                            </div>
                        </b-alert>
                    </div>
                    <!-- overlay -->
                    <b-overlay
                        v-if="showButton"
                        :show="loading"
                        rounded
                        opacity="0.6"
                        spinner-variant="primary"
                    >
                        <b-button
                            block
                            variant="primary"
                            v-if="showButton"
                            @click="createPayment"
                            >Proceed To Payment</b-button
                        >
                    </b-overlay>
                </div>
            </b-card>
            <b-card class="text-center" title="Discount code" v-if="showButton">
                <b-form-input
                    v-model="discount_code"
                    trim
                    type="text"
                    :state="inputStateDiscount"
                    :readonly="inputStateDiscount"
                />
                <small class="text-danger" v-if="textResponseDiscount">{{
                    textResponseDiscount
                }}</small>
                <b-button
                    variant="primary"
                    class="mt-1"
                    :disabled="disabledButtonApply"
                    block
                    @click="onApplyDiscount"
                    v-if="!showRemoveButton"
                >
                    Apply</b-button
                >
                <b-button
                    variant="danger"
                    class="mt-1"
                    block
                    v-if="showRemoveButton"
                    @click="removeDiscountCode"
                >
                    Remove</b-button
                >
            </b-card>
        </div>

        <b-modal
            v-model="showModal"
            hide-footer
            title="Proceed To Payment"
            @hide="hideModal"
        >
            <b-form
                id="createPaymentForm"
                :method="form['method']"
                :action="form['action']"
            >
                <input
                    type="hidden"
                    v-for="item in form['parameters']"
                    :key="item.id"
                    :name="item.name"
                    :value="item.value"
                />
                <b-button
                    variant="primary"
                    class="mx-auto d-block"
                    type="submit"
                    >Continue</b-button
                >
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BAlert,
    BButton,
    BFormInput,
    BOverlay,
    BModal,
    BForm,
    BImg,
    BLink,
} from "bootstrap-vue";
export default {
    components: {
        BCard,
        BAlert,
        BButton,
        BFormInput,
        BOverlay,
        BModal,
        BForm,
        BImg,
        BLink,
    },
    data() {
        return {
            qr_png: false,
            gateway_voucher: false,
            type_account: "",
            account: "",
            is_manual: false,
            form: [],
            showModal: false,
            loading: false,
            method: "---",
            is_recurring: "checking",
            plan_name: "---",
            duration: 0,
            currency: "",
            subtotal: 0,
            fee: 0,
            total: 0,
            discount: 0,
            showButton: false,
            discount_code: "",
            disabledButtonApply: true,
            inputStateDiscount: null,
            textResponseDiscount: null,
            showRemoveButton: false,
            totalwithoutDiscount: 0,
            is_card: false,
        };
    },
    created() {
        this.fetchPlans();
    },
    computed: {
        isRecurring() {
            const typeRecurring = {
                true: "Recurring Payment",
                false: "One-Time Payment",
                checking: "---",
            };

            return typeRecurring[this.is_recurring];
        },
    },
    methods: {
        fetchPlans() {
            this.$store
                .dispatch("fetchPlans")
                .then((res) => {
                    var plan = this.filterbyID(
                        res.data,
                        this.$route.params.plan_id
                    );
                    if (plan.length != 0) {
                        this.plan_name = plan[0].name;
                        this.duration = plan[0].duration;
                        this.subtotal = parseFloat(plan[0].price);
                    }
                    this.fetchPayments();
                })
                .catch((err) => console.log(err));
        },
        fetchPayments() {
            this.$store
                .dispatch("fetchPaymentMethods")
                .then((res) => {
                    var payment = this.filterbyID(
                        res.data.data,
                        this.$route.params.payment_id
                    );
                    if (payment.length != 0) {
                        if (payment[0].type.includes("manual")) {
                            var pay_manual = payment[0].type.split(":");
                            this.is_manual = true;
                            this.type_account = pay_manual[1];
                            this.account = pay_manual[2];
                            if (pay_manual[3] != undefined)
                                this.qr_png = pay_manual[3];
                        } else if (payment[0].type.includes("voucher")) {
                            this.gateway_voucher = true;
                        }
                        if (payment[0].description.includes("Card")) {
                            this.is_card = true;
                        }
                        this.method = payment[0].name;
                        this.is_recurring = payment[0].is_recurring;
                        this.currency = payment[0].currency;
                        this.fee = parseFloat(
                            (
                                (parseFloat(this.subtotal) *
                                    parseFloat(payment[0].transaction_fee)) /
                                    100 +
                                parseFloat(payment[0].fixed_fee)
                            ).toFixed(2)
                        );

                        this.total = (this.subtotal + this.fee).toFixed(2);
                        if (this.total > 0 && !this.is_manual) {
                            this.showButton = true;
                            this.disabledButtonApply = false;
                        }
                    }
                })
                .catch((err) => console.log(err));
        },

        filterbyID(json, id) {
            return json.filter(function (element) {
                if (element.id == id) {
                    return true;
                } else {
                    return false;
                }
            });
        },

        createPayment() {
            this.loading = true;
            var query = {
                pid: this.$route.params.plan_id,
                pmid: this.$route.params.payment_id,
            };
            if (this.showRemoveButton)
                query["discount_code"] = this.discount_code;
            this.$store
                .dispatch("createPayment", query)
                .then((res) => {
                    if (res.data.success) {
                        if (res.data.redirect)
                            window.location.href = res.data.redirect;
                        else if (res.data.form) {
                            this.showModal = true;
                            this.form = res.data.form;
                        }
                    } else {
                        this.loading = false;
                        this.$bvToast.toast(res.data.message, {
                            title: "Message",
                            variant: "danger",
                            toaster: "b-toaster-bottom-right",
                            solid: true,
                        });
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        onApplyDiscount() {
            if (this.discount_code != "") {
                this.discount_code.toUpperCase();
                this.disabledButtonApply = true;
                var query = {
                    pid: this.$route.params.plan_id,
                    pmid: this.$route.params.payment_id,
                    discount_code: this.discount_code,
                };
                this.$store
                    .dispatch("applyDiscount", query)
                    .then((res) => {
                        if (res.data.success) {
                            this.textResponseDiscount = null;
                            this.inputStateDiscount = true;
                            this.showRemoveButton = true;
                            this.discount = parseFloat(
                                res.data.discount_amount
                            );
                            this.totalwithoutDiscount = this.total;
                            this.total = (this.total - this.discount).toFixed(
                                2
                            );
                        } else {
                            this.inputStateDiscount = false;
                            this.textResponseDiscount = res.data.message;
                            this.disabledButtonApply = false;
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                this.inputStateDiscount = false;
                this.textResponseDiscount = "Please enter the discount code.";
            }
        },

        removeDiscountCode() {
            this.inputStateDiscount = null;
            this.showRemoveButton = false;
            this.discount_code = "";
            this.total = this.totalwithoutDiscount;
            this.discount = 0;
            this.disabledButtonApply = false;
        },

        hideModal() {
            this.loading = false;
            this.form = [];
        },
    },
};
</script>

<style>
.payment-summary {
    max-width: 520px;
    margin: 0 auto;
}
</style>
